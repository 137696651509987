import Button from "lib/components/Button"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { NextSeo } from "next-seo"
import { useRouter } from "next/router"
import { useContext } from "react"
import url from "url"

export default function FourOhFour() {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  const { pathname } = url.parse(router.asPath)

  return (
    <div>
      <NextSeo title={`${t("page_not_found")} | PasarTrainer`} canonical={`${process.env.NEXT_PUBLIC_URL}${pathname}`}/>
      <section className="bg-white flex items-center justify-center h-screen-80">
        <div className="py-8 px-4 mx-auto lg:py-16 lg:px-6 my-auto">
          <div className="mx-auto text-center">
            <Text
              alignText="center"
              color="primary"
              customClass="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl">
              404
            </Text>
            <h1
              className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl text-center">
              {t("message_page_not_found")}
            </h1>
            <Text
              alignText="center"
              customClass="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              {t("you_will_find_lots_homepage")}
            </Text>
            <div className="flex items-center justify-center">
              <Button variant="contained" color="primary" href="/">
                {t("back_to_home")}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
